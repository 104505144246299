import React, { useEffect, useState } from 'react';
import axios from '../../../lib/axios';
import Loading from '../spinners/loading';
import List from './list';

const Summary = ({ labels, size, from, to, isProduction }) => {
  const [shopSummaries, setShopSummaries] = useState([]);
  const [labelId, setLabelId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const getShopSummaries = (from, to, label_id, size) => {
    if (isInitialized) {
      setLoading(true);
      axios.get(`shop_summaries/list`, {params: { size: size, label_id: labelId, from: from, to: to }})
        .then((response) => {
          setShopSummaries(response.data);
          setLoading(false);
        });  
    } else {
      setTimeout(function() {
        setIsInitialized(true);
        setLoading(true);
        axios.get(`shop_summaries/list`, {params: { size: size, label_id: labelId, from: from, to: to }})
          .then((response) => {
            setShopSummaries(response.data);
            setLoading(false);
          });
      }, 500);
    }    
  };

  const numbering = () => {
    $('#datatable_fixed_header1 tbody tr td:first-child').each((index, td) => {
      td.innerText = index + 1;
    });
  };

  useEffect(() => {
    getShopSummaries(from, to, labelId, size);
  }, [labelId]);

  useEffect(() => {
    // ラベル選択用
    $('[data-bs-toggle="tooltip"]').tooltip();
    $('#shop-summary-shop-label').select2();
    $('#shop-summary-shop-label').on('select2:select', (e) => {
      const value = e.currentTarget.value;
      if (!isNaN(parseInt(value))) {
        setLabelId(value);
      } else {
        setLabelId('');
      }
    });

    if (shopSummaries.length === 0 || $("#datatable_fixed_header1").hasClass('dataTable')) {
      return;
    }

    const datatable = new DataTable("#datatable_fixed_header1", {
      paging: false,
      info : false,
      scrollX:        false,
      scrollCollapse: false,
      order: [[ 2, "desc" ]],
      columns: [
        null,
        null,
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
        { orderSequence: ['desc', 'asc'] },
      ],
      columnDefs: [{
        targets: [0, 1],
        orderable: false
      }],
      fixedHeader:{
        header: true,
        headerOffset: 0
      },
      fixedColumns: {
        leftColumns: 2,
      },
    });
    datatable.on('order', () => {
      numbering();
    });
  }, [shopSummaries, loading]);

  return (
    <div className="col-xl-12 col-lg-12 col-sm-12 col-xs-12">
      <div className="card">
        <div className="card-header border-0 pt-6">
          <div className="card-title">
            <div className="symbol symbol-30px me-4">
              <span className="symbol-label bg-light-primary">
                <span className="svg-icon svg-icon-2 svg-icon-primary">
                  <span className="section-icon">
                    <i className="ki-duotone ki-data">
                      <i className="path1"></i>
                      <i className="path2"></i>
                      <i className="path3"></i>
                      <i className="path4"></i>
                      <i className="path5"></i>
                    </i>
                  </span>
                </span>
              </span>
            </div>
            <span className="text-gray-800 fw-bolder fs-5">店舗別サマリー</span>
          </div>

          {
            isProduction == false ? (
              <div className="card-toolbar gap-4">
                <div className="w-auto d-flex align-items-center mobile-w-100">
                  <div className="text-gray-600 fs-8 me-3 nowrap">店舗ラベル</div>
                  <div className="mobile-w-100">
                    <select
                      className="form-select form-select-sm w-200px mobile-w-100"
                      id="shop-summary-shop-label"
                      data-control="select2"
                      data-placeholder="ラベルを選択"
                    >
                      <option>すべて</option>
                      {labels.map((label) => {
                        return <option key={`label-${label.id}`} value={label.id}>{label.name}</option>
                      })}
                    </select>
                  </div>
                </div>
                <a className="btn btn-sm btn-primary fs-7 align-items-center mobile-w-100" href="/shop_summaries">
                  <i className="ki-outline ki-down fs-6 text-gray ms-n1 mb-1"></i>
                  すべて表示
                </a>
              </div>
            ) : (
              <div className="card-toolbar gap-4">
                <a className="btn btn-sm btn-primary fs-7 align-items-center mobile-w-100" href="/shop_summaries">
                  <i className="ki-outline ki-down fs-6 text-gray ms-n1 mb-1"></i>
                  すべて表示
                </a>
              </div>
            )
          }          
        </div>
        {loading ? (
          <Loading />
        ) : (
          <List shopSummaries={shopSummaries} />
        )}
      </div>
    </div>
  );
};

export default Summary;

