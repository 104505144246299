import React, { Component } from 'react';
import PropTypes from "prop-types";

const NoUnmatchedItemList = () => {
  return <>no item</>
}

export default class UnmatchedItems extends Component {
  constructor(props) {
    super(props)

    this.editorStyle = this.props.editorStyle
  }

  newItemLink = (item, shopVendor, deliveryNotePhoto) => {
    return `/cs/shops/${deliveryNotePhoto.shop.id}/unofficial_items/new?shop_vendor_id=${shopVendor.id}&item_name=${item.item_name}&tax_type=${item.tax_type}&tax_rate=${item.tax_rate}`
  }

  UnmatchedItemList = () => {
    return (
      <div className={this.editorStyle.joinClassNames(this.editorStyle.card(), this.editorStyle.marginTop(), this.editorStyle.backgroundWarning())}>
        <div className={this.editorStyle.cardContent()}>
          <div className={this.editorStyle.columns()}>
            <div className={this.editorStyle.column()}>
              <div style={{marginBottom: '10px'}}>
                <strong>未登録の可能性のある商品</strong>
                <br/>
                <small>登録後に再度、商品をAIで追加ボタンを押すと自動で追加できます</small>
              </div>            
              { this.props.items.map((item, index) => {
                return (
                  <div>
                    <div className={this.editorStyle.columns()}>
                      <div className={this.editorStyle.column()}>
                        <div className={this.editorStyle.field()}>
                          <span style={{marginRight: '10px'}}>・商品名: {item.item_name} 税区分: {item.tax_type}（{item.tax_rate}）</span>
                          <a href={this.newItemLink(item, this.props.shopVendor, this.props.deliveryNotePhoto)} target="blank" className={this.editorStyle.joinClassNames(this.editorStyle.buttonPrimary(), this.editorStyle.buttonSmall())}>登録</a>                          
                        </div>
                      </div>
                      <hr />
                    </div>                    
                  </div>
                )  
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }

  // { items.length > 0 ? this.UnmatchedItemList() : <NoUnmatchedItemList />}

  render() {
    const { items } = this.props;
    return (
      <>        
        { items.length > 0 ? this.UnmatchedItemList() : ""}
      </>
    )
  }
}

UnmatchedItems.propTypes = {
  items: PropTypes.array.isRequired
}