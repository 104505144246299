import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios'
import PropTypes from "prop-types";
import { calcTax, calcSubtotalTaxExcluded, calcSubtotal } from '../tax_calculator';

export default class AddItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      officialItemsHash: null,
      selectedOfficialItem: null,
      selectedItem: null,
      itemArrivalNum: 0,
      itemPrice: 0,
      itemSubtotalTaxExcluded: 0,
      itemTax: 0,
      itemSubtotal: 0
    }

    this.editorStyle = this.props.editorStyle;
  }

  handleArrivalNumChange = (e) => {
    if (e.currentTarget.value && this.state.itemPrice) {
      let taxRate = this.state.selectedOfficialItem == null ? 0 : this.state.selectedOfficialItem.taxRate
      let subtotalTaxExcluded = calcSubtotalTaxExcluded(e.currentTarget.value, this.state.itemPrice, this.state.selectedOfficialItem.taxType, taxRate)
      let tax = calcTax(e.currentTarget.value, this.state.itemPrice, this.state.selectedOfficialItem.taxType, taxRate)
      let subtotal = calcSubtotal(e.currentTarget.value, this.state.itemPrice, this.state.selectedOfficialItem.taxType, taxRate)
      this.setState({itemArrivalNum: e.currentTarget.value, itemSubtotalTaxExcluded: subtotalTaxExcluded, itemTax: tax, itemSubtotal: subtotal})
    } else {
      this.setState({itemArrivalNum: e.currentTarget.value})
    }
  }

  handlePriceChange = (e) => {
    if (this.state.itemArrivalNum && e.currentTarget.value) {
      let taxRate = this.state.selectedOfficialItem == null ? 0 : this.state.selectedOfficialItem.taxRate
      let subtotalTaxExcluded = calcSubtotalTaxExcluded(this.state.itemArrivalNum, e.currentTarget.value, this.state.selectedOfficialItem.taxType, taxRate)
      let tax = calcTax(this.state.itemArrivalNum, e.currentTarget.value, this.state.selectedOfficialItem.taxType, taxRate)
      let subtotal = calcSubtotal(this.state.itemArrivalNum, e.currentTarget.value, this.state.selectedOfficialItem.taxType, taxRate)
      this.setState({itemPrice: e.currentTarget.value, itemSubtotalTaxExcluded: subtotalTaxExcluded, itemTax: tax, itemSubtotal: subtotal})
    } else {
      this.setState({itemPrice: e.currentTarget.value})
    }   
  }

  handleSubtotalTaxExcludedChange = (e) => {
    this.setState({itemSubtotalTaxExcluded: e.currentTarget.value})
  }

  handleTaxChange = (e) => {
    this.setState({itemTax: e.currentTarget.value})
  }

  handleSubtotalChange = (e) => {
    this.setState({itemSubtotal: e.currentTarget.value})
  }

  handleChange = selectedItem => {
    let selectedOfficialItem = this.state.officialItemsHash[selectedItem.value]
    let itemPrice = selectedOfficialItem.latestPrice == null ? 0 : selectedOfficialItem.latestPrice
    let taxRate = this.state.selectedOfficialItem == null ? 0 : this.state.selectedOfficialItem.taxRate
    let itemSubtotalTaxExcluded = calcSubtotalTaxExcluded(this.state.itemArrivalNum, itemPrice, selectedOfficialItem.taxType, taxRate)
    let itemTax = calcTax(this.state.itemArrivalNum, itemPrice, selectedOfficialItem.taxType, taxRate)
    this.setState(
        { selectedOfficialItem, selectedItem, itemPrice, itemSubtotalTaxExcluded, itemTax },
        () => console.log(`Option selected:`, this.state.selectedItem)
    );
  };

  promiseOptions = inputValue => {
    const token = document.getElementsByName('csrf-token').item(0).content
    const url = Routes.commons_api_delivery_note_photos_shop_vendor_official_items_path(this.props.shopVendor.id, {query: inputValue})
    let params = new FormData()
    params.append("_method", "get")
    params.append("authenticity_token", token)

    let self = this
    return new Promise(function(resolve, reject) {
      axios.get(url, params)
          .then(response => {
            let officialItems = response.data['official_items']
            self.setOfficialItemsHash(officialItems)
            resolve(officialItems.map(({ id, name, code }) => ({ value: id, label: name.concat(code) })))
          })
          .catch((e) => {
            console.log(e);
            alert('商品の検索に失敗しました。')
          })
    })
  }

  setOfficialItemsHash = (officialItems) => {
    let officialItemsHash = Object.fromEntries(
        officialItems.map(item => [item.id, item])
    )
    this.setState({officialItemsHash: officialItemsHash})
  }

  render () {
    const { selectedOfficialItem, selectedItem, itemArrivalNum, itemPrice, itemSubtotalTaxExcluded, itemTax, itemSubtotal } = this.state;
    const { shop, shopVendor, handleAddItem, hideAddItem } = this.props;
    return (
      <div className={this.editorStyle.joinClassNames(this.editorStyle.card(), this.editorStyle.marginTop())}>
        <div className={this.editorStyle.cardContent()}>
          <div className={this.editorStyle.columns()}>
            <div className={this.editorStyle.column()}>
              <h5 className={this.editorStyle.title()}>商品を検索から追加</h5>
            </div>
            <div className={this.editorStyle.joinClassNames(this.editorStyle.column(), this.editorStyle.buttons())} align="right">
              <a className={this.editorStyle.joinClassNames(this.editorStyle.buttonLink(), this.editorStyle.buttonSmall())} target="blank" href={this.editorStyle.isCs() ? `/cs/shop_vendors/${shopVendor.id}/unofficial_items` : `/shop_vendors/${shopVendor.id}/items`}>商品一覧</a>
              {
                this.editorStyle.isCs() ? <a className={this.editorStyle.joinClassNames(this.editorStyle.buttonLink(), this.editorStyle.buttonSmall())} target="blank" href={`/cs/shops/${shop.id}/unofficial_items/new?shop_vendor_id=${shopVendor.id}`}>商品登録</a> : ''
              }              
            </div>
          </div>
          <div className={this.editorStyle.field()}>
            <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.clearfix())}>
              <div style={{float: 'left'}}>
                <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>商品</label>
              </div>
              { selectedOfficialItem == null ? '' :
                <div style={{float: 'right'}}>
                  <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>
                    <a target="blank" href={this.editorStyle.isCs() ? `/cs/shops/${shop.id}/unofficial_items/${selectedOfficialItem.unofficialItemId}/edit` : `/shop_vendors/${shopVendor.id}/items/${selectedOfficialItem.unofficialItemId}/edit`}>
                      「{selectedOfficialItem.name}{selectedOfficialItem.code}」を編集
                    </a>
                  </label>
                </div>
              }
            </div>
            <div className={this.editorStyle.field()}>
              <AsyncSelect
                  value={selectedItem}
                  onChange={this.handleChange}
                  defaultOptions
                  loadOptions={this.promiseOptions}
                  placeholder="商品を選択"
              />
            </div>
            <div className={this.editorStyle.field()}>
              <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small(), this.editorStyle.marginTop())}>
                税区分： {selectedOfficialItem == null ? 'ー' : selectedOfficialItem.taxTypeName}
              </label>
            </div>
          </div>
          <div className={this.editorStyle.columns()}>
            <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
              <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>納品数</label>
              <div className={this.editorStyle.control()}>
                <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={itemArrivalNum} id="deliveryNum" onChange={this.handleArrivalNumChange} disabled={selectedOfficialItem == null ? true : false}/>
              </div>
            </div>
            <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
              <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>納品単価</label>
              <div className={this.editorStyle.control()}>
                <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={itemPrice} id="deliveryPrice" onChange={this.handlePriceChange} disabled={selectedOfficialItem == null ? true : false}/>
              </div>
            </div>
            <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
              <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>小計（税別）</label>
              <div className={this.editorStyle.control()}>
                <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={itemSubtotalTaxExcluded} id="deliverySubtotal" onChange={this.handleSubtotalTaxExcludedChange} disabled={selectedOfficialItem == null ? true : false}/>
              </div>
            </div>
            <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
              <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>小計（税額）</label>
              <div className={this.editorStyle.control()}>
                <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={itemTax} id="deliverySubtotal" onChange={this.handleTaxChange} disabled={selectedOfficialItem == null ? true : false}/>
              </div>
            </div>
            <div className={this.editorStyle.joinClassNames(this.editorStyle.field(), this.editorStyle.column())}>
              <label className={this.editorStyle.joinClassNames(this.editorStyle.label(), this.editorStyle.small())}>小計（税込）</label>
              <div className={this.editorStyle.control()}>
                <input className={this.editorStyle.joinClassNames(this.editorStyle.input(), this.editorStyle.small())} type="number" value={itemSubtotal} id="deliverySubtotal" onChange={this.handleSubtotalChange} disabled={selectedOfficialItem == null ? true : false}/>
              </div>
            </div>
          </div>
          <div>
            <div className={this.editorStyle.marginTop()} align="right">
              <button className={this.editorStyle.buttonLink()} disabled={selectedOfficialItem == null} onClick={(e) => handleAddItem(selectedOfficialItem, itemArrivalNum, itemPrice, itemSubtotalTaxExcluded, itemTax, itemSubtotal, e)}>追加する</button>
            </div>
            <div className={this.editorStyle.marginTop()}>
              <button className={this.editorStyle.buttonLight()} onClick={hideAddItem}>キャンセル</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AddItem.propTypes = {
  shop: PropTypes.object.isRequired,
  shopVendor: PropTypes.object.isRequired,
  handleAddItem: PropTypes.func.isRequired,
  hideAddItem: PropTypes.func.isRequired
}
