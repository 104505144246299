import React, { useState, useEffect } from 'react';
import 'viewerjs/dist/viewer.css';
import Viewer from 'viewerjs';

const ImageViewer = ({src}) => {
  useEffect(() => {
    const viewer = new Viewer(document.getElementById('fax_image'), {
      inline: true,
      navbar: false,
      title: false,
      toolbar: {
        zoomIn: 1,
        zoomOut: 1,
        oneToOne: 1,
        reset: 1,
        prev: false,
        play: false,
        next: false,
        rotateLeft: 1,
        rotateRight: 1,
        flipHorizontal: 1,
        flipVertical: 1,
      },
      viewed() {},
    });
  }, []);

  return (
    <div style={{ height: '1000px' }}>
      <img src={src} className="fax_iamge" id="fax_image" style={{display: 'none'}} />
    </div>
  );
}

export default ImageViewer;
