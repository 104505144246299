// styleを取ってclass名を返すクラスを作成する
// bulmaベースのネーミング

export default class EditorStyle {
  constructor(style) {
    this.style = style
  }
  
  joinClassNames(...args) {
    return args.join(' ')
  }

  say() {
    alert('hello')
  }

  isCs() {
    return this.style == 'cs'
  }

  isManager() {
    return this.style == 'manager'
  }

  marginTop() {
    return this.isCs() ? 'margin-small-top' : 'mt-3'
  }

  marginRight() {
    return this.isCs() ? 'margin-small-right' : 'me-3'
  }

  box() {
    return this.isCs() ? 'box' : 'card shadow-sm'
  }

  boxHeader() {
    return this.isCs() ? '' : 'card-header'
  }

  boxDetail() {
    return this.isCs() ? '' : 'card-body'
  }
  
  card() {
    return this.isCs() ? 'card' : 'card shadow-sm'
  }

  cardContent() {
    return this.isCs() ? 'card-content' : 'card-body'
  }

  title() {
    return this.isCs() ? 'title is-5' : 'card-title'
  }

  label() {
    return this.isCs() ? 'label' : 'form-label'
  }

  textRight() {
    return this.isCs() ? 'has-text-right' : 'text-end'
  }

  input() {
    return this.isCs() ? 'input' : 'form-control'
  }

  buttonLight() {
    return this.isCs() ? 'button' : 'btn btn-light'
  }

  buttonSmall() {
    return this.isCs() ? 'button is-small' : 'btn btn-sm'
  }

  buttonDanger() {
    return this.isCs() ? 'button is-danger' : 'btn btn-danger'
  }

  buttonPrimary() {
    return this.isCs() ? 'button is-primary' : 'btn btn-primary'
  }

  buttonLink() {
    return this.isCs() ? 'button is-link' : 'btn btn-info'
  }

  buttonWarning() {
    return this.isCs() ? 'button is-warning' : 'btn btn-warning'
  }

  tabItemGroup() {
    return this.isCs() ? 'field is-grouped is-grouped-multiline' : 'clearfix'
  }

  tabItem() {
    return this.isCs() ? 'control' : 'float-start d-inline pe-2 pb-2'
  }

  tagSuccess() {
    return this.isCs() ? 'tag is-success' : 'btn btn-success btn-sm float-start'
  }

  tagLight() {
    return this.isCs() ? 'tag is-light' : 'btn btn-light btn-sm float-start'
  }

  block() {
    return this.isCs() ? 'block' : 'pt-2 pb-2'
  }

  columns() {
    return this.isCs() ? 'columns' : 'row'
  }

  column() {
    return this.isCs() ? 'column' : 'col'
  }

  field() {
    return this.isCs() ? 'field' : 'clearfix'
  }

  small() {
    return this.isCs() ? 'is-small' : 'small opacity-100'
  }

  control() {
    return this.isCs() ? 'control' : ''
  }

  buttons() {
    return this.isCs() ? 'buttons' : ''
  }

  table() {
    return this.isCs() ? 'table is-fullwidth' : 'table'
  }

  clearfix() {
    return this.isCs() ? 'is-clearfix' : 'clearfix'
  }

  backgroundWarning() {
    return this.isCs() ? 'has-background-warning' : 'bg-warning'
  }
} 

